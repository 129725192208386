import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://storage.googleapis.com/blog-273402.appspot.com/PANO_20190310_153920.vr.jpg" className="App-logo" alt="logo" />
        <p>
          Antarctica trip coming soon!!!
        </p>
      </header>
    </div>
  );
}

export default App;
